exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-calculator-estimate-footprint-coal-power-plant-js": () => import("./../../../src/pages/life-calculator/estimate-footprint/coal-power-plant.js" /* webpackChunkName: "component---src-pages-life-calculator-estimate-footprint-coal-power-plant-js" */),
  "component---src-pages-life-calculator-estimate-footprint-index-js": () => import("./../../../src/pages/life-calculator/estimate-footprint/index.js" /* webpackChunkName: "component---src-pages-life-calculator-estimate-footprint-index-js" */),
  "component---src-pages-life-calculator-estimate-footprint-natural-gas-export-facility-js": () => import("./../../../src/pages/life-calculator/estimate-footprint/natural-gas-export-facility.js" /* webpackChunkName: "component---src-pages-life-calculator-estimate-footprint-natural-gas-export-facility-js" */),
  "component---src-pages-life-calculator-estimate-footprint-natural-gas-power-plant-js": () => import("./../../../src/pages/life-calculator/estimate-footprint/natural-gas-power-plant.js" /* webpackChunkName: "component---src-pages-life-calculator-estimate-footprint-natural-gas-power-plant-js" */),
  "component---src-pages-life-calculator-estimate-footprint-oil-refinery-js": () => import("./../../../src/pages/life-calculator/estimate-footprint/oil-refinery.js" /* webpackChunkName: "component---src-pages-life-calculator-estimate-footprint-oil-refinery-js" */),
  "component---src-pages-life-calculator-index-js": () => import("./../../../src/pages/life-calculator/index.js" /* webpackChunkName: "component---src-pages-life-calculator-index-js" */),
  "component---src-pages-programs-index-js": () => import("./../../../src/pages/programs/index.js" /* webpackChunkName: "component---src-pages-programs-index-js" */)
}

